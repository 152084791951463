import {
  hmoTypes,
  PlandalfFilterPreference,
  type PlandalfPreference,
  type PlandalfQuote,
  PlandalfSortPreference,
  ppoTypes,
  type PreferenceConfig,
  type PreferenceType
} from '~/models/PlandalfModels'
import { SessionStore } from '~/stores/session'
import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

export default class PlandalfPreferences {
  static use() {
    const session = SessionStore.use()

    const preferences = ref<PlandalfPreference[]>(_cloneDeep(session.plandalfPreferences))

    const config: PreferenceConfig = {
      ['doctors']: {
        preference: new PlandalfSortPreference('doctors'),
        sort: (quote: QuotedPlanVm) => {
          const prefs = findPreference('doctors')?.value?.values ?? []

          return (
            quote.providerCoverage.providers?.filter((x) => x.inNetwork && prefs.includes(x.npi!))
              ?.length ?? 0
          )
        },
        direction: 'desc'
      },
      ['drugs']: {
        preference: new PlandalfSortPreference('drugs'),
        sort: (quote: QuotedPlanVm) => quote.drugCost ?? 0,
        direction: 'asc'
      },
      ['benefits']: {
        preference: new PlandalfSortPreference('benefits'),
        sort: (quote: QuotedPlanVm) => {
          const prefs = findPreference('benefits')?.value?.values ?? []
          return (
            quote.additionalBenefits.additionalBenefits?.filter(
              (x) => x.covered && prefs.includes(x.sortKey)
            )?.length ?? 0
          )
        },
        direction: 'desc'
      },
      ['carriers']: {
        preference: new PlandalfFilterPreference('carriers'),
        filter: (quote: QuotedPlanVm) => {
          const filters = findPreference('carriers')?.value?.values ?? []

          return !filters.includes(quote.details.carrierFilterKey)
        },
        direction: null
      },
      ['policyType']: {
        preference: new PlandalfFilterPreference('policyType'),
        filter: (quote: QuotedPlanVm) => {
          let filters = findPreference('policyType')?.value?.values ?? []

          if (filters.includes('hmo')) {
            filters = hmoTypes
          } else {
            filters = ppoTypes
          }

          return !filters.includes(QuoteUtils.mapPolicyType(quote.policyType, quote.snpType))
        },
        direction: null
      }
    }

    const filterPreferences = computed(
      () => preferences.value.filter((x) => x.action == 'filter') as PlandalfFilterPreference[]
    )

    const sortPreferences = computed(
      () => preferences.value.filter((x) => x.action == 'sort') as PlandalfSortPreference[]
    )

    const hasPendingPreferences = computed(() =>
      _isEqual(preferences.value, session.plandalfPreferences)
    )

    function findPreference(type: PreferenceType) {
      const index = preferences.value.findIndex((x) => x.type === type)
      if (index < 0) return null
      return {
        index: index,
        value: preferences.value[index]
      }
    }

    function updatePreference(type: PreferenceType, value: any | any[]) {
      let maybePreference = findPreference(type)

      if (!maybePreference) {
        // add new
        maybePreference = {
          index: 0,
          value: config[type].preference
        }

        preferences.value.push(maybePreference.value)
      }

      if (Array.isArray(value)) {
        // replace all values

        maybePreference.value.values = value
      } else {
        // add or remove existing value

        const valueIndex = maybePreference.value.values.indexOf(value)
        if (valueIndex > -1) {
          maybePreference.value.values.splice(valueIndex, 1)
        } else {
          maybePreference.value.values.push(value)
        }
      }

      // if no values remove preference
      if (!maybePreference.value.values.length) {
        splicePreference(maybePreference.index)
        return
      }

      // sort values for consistent comparisons
      maybePreference.value.values = maybePreference.value.values.sort()

      // move to front of array
      preferences.value.unshift(splicePreference(maybePreference.index)[0])
    }

    function splicePreference(index: number) {
      return preferences.value.splice(index, 1)
    }

    function getAppliedFilters(quote: QuotedPlanVm) {
      const appliedFilters: { index: number; type: PreferenceType }[] = []

      filterPreferences.value.forEach((filter, index) => {
        if (config[filter.type].filter!(quote)) {
          appliedFilters.push({
            index: index,
            type: filter.type
          })
        }
      })

      return appliedFilters
    }

    function sortPlans(quotes: QuotedPlanVm[]) {
      let sortMethods: Array<(quote: QuotedPlanVm) => number> = []
      let sortDirections: Array<'asc' | 'desc'> = []

      // TODO: first sort plans so that filtered plans go to bottom
      // sortMethods.push((quote: QuotedPlanVm) => {
      //   const filters = getAppliedFilters(quote)
      //
      //   // lower index means the filter was applied more recently, making it higher priority
      //   const priority = filters.reduce((a, b) => a + b.index, 0)
      //
      //   const filterScore = filters.length - priority
      //
      //   return filterScore
      // })
      // sortDirections.push('asc')

      // then sort by preferences
      sortMethods = [...sortMethods, ...sortPreferences.value.map((x) => config[x.type].sort!)]
      sortDirections = [
        ...sortDirections,
        ...sortPreferences.value.map((x) => config[x.type].direction!)
      ]

      console.log('SORT METHODS: ', sortMethods)

      // default sorts
      const { costElements } = QuoteUtils.use()
      sortMethods.push((q: QuotedPlanVm) => q.rawScore)
      sortDirections.push('desc')
      sortMethods.push((q: QuotedPlanVm) => q.getYearlyEstCost(costElements.value))
      sortDirections.push('asc')

      const sorted = _orderBy(quotes, sortMethods, sortDirections)

      console.log('SORTED PLANS: ', sorted)

      return sorted
    }

    function applyPreferences(quotes: QuotedPlanVm[]) {
      console.log('APPLY PREFERENCES: ', session.plandalfPreferences, preferences.value)
      session.plandalfPreferences = _cloneDeep(preferences.value)

      const sorted = sortPlans(quotes)

      return sorted.map((quote, index) => ({
        index: index,
        filteredBy: getAppliedFilters(quote),
        quote: quote
      })) as PlandalfQuote[]
    }

    function resetPreferences() {
      console.log('reset preferences')
      preferences.value = _cloneDeep(session.plandalfPreferences)
    }

    return {
      preferences,
      hasPendingPreferences,
      findPreference,
      updatePreference,
      applyPreferences,
      resetPreferences
    }
  }
}
