import guid from '~/utils/guid'

const touches = ref(0)

export class CookieJar {
  static use() {
    const config = useRuntimeConfig()

    const cookieName = (name: string) => `${config.public.cookieEnvPrefix}${name}`

    const deviceId = computed(() => {
      touches.value
      const cookie = useCookie(cookieName(config.public.deviceCookie), { secure: true })

      if (!cookie.value) {
        cookie.value = guid()
      }

      return cookie.value
    })

    const sessionId = computed(() => {
      touches.value
      return useCookie(cookieName(config.public.sessionCookie)).value
    })

    const auth = computed(() => {
      touches.value
      return useCookie(cookieName(config.public.authCookie)).value
    })

    const touch = () => {
      touches.value++
    }

    return {
      touch,
      deviceId,
      sessionId,
      auth
    }
  }
}
