import { defineStore } from 'pinia'
import { ConversationContext } from '~/models/ConversationModels'

export class ConversationStore {
  static use = defineStore('conversation', () => {
    const loading = ref<boolean>(false)
    const sectionId = ref<string | null>(null)
    const currentSectionId = ref<string | null>(null)
    const currentContext = ref<ConversationContext | null>(null)
    const initialData = ref<Record<string, any>>({})

    async function loadSection(id: string, data: Record<string, any> = {}) {
      sectionId.value = null
      initialData.value = data
      await nextTick(() => {
        sectionId.value = id
      })
    }

    return {
      loading,
      currentSectionId,
      sectionId,
      currentContext,
      initialData,
      loadSection
    }
  })
}
