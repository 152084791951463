import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import { PolicyType } from '~/generated/api-clients-generated'

export const hmoTypes = [PolicyType.HMO, PolicyType.HMOPOS]
export const ppoTypes = [PolicyType.PPO, PolicyType.LPPO, PolicyType.RPPO]

export type PlandalfConfig = {
  testMode: boolean
}

export const PreferenceTypes = ['drugs', 'doctors', 'benefits', 'carriers', 'policyType'] as const
export type PreferenceType = (typeof PreferenceTypes)[number]

export type PreferenceAction = 'filter' | 'sort'

export interface PlandalfPreference {
  type: PreferenceType
  action: PreferenceAction
  values: any[]
}

export class PlandalfSortPreference implements PlandalfPreference {
  type: PreferenceType
  action: PreferenceAction = 'sort'
  values: any[] = []

  constructor(key: PreferenceType) {
    this.type = key
  }
}

export class PlandalfFilterPreference implements PlandalfPreference {
  type: PreferenceType
  action: PreferenceAction = 'filter'
  values: any[] = []

  constructor(key: PreferenceType) {
    this.type = key
  }
}

export type PlandalfRecentlyViewed = {
  [key: number]: {
    medicareId: string
    preferences: PlandalfPreference[]
  }[]
}

export type PreferenceConfig = {
  [key: PreferenceType | string]: {
    preference: PlandalfPreference
    sort?: (quote: QuotedPlanVm) => number
    filter?: (quote: QuotedPlanVm) => boolean
    direction: 'asc' | 'desc' | null
  }
}

export type PlandalfQuote = {
  quote: QuotedPlanVm
  index: number
  filteredBy: Array<{ type: PreferenceType; index: number }>
}

export type PlandalfResponse = {
  needsConfirmation?: boolean
  message?: string
}

export class PlandalfRecommendation {
  preferences: PlandalfPreference[]
  newQuote: QuotedPlanVm
  oldRec?: PlandalfRecommendation | null

  get carrierPreferences() {
    return this.preferences.find((x) => x.type == 'carriers')
  }

  get doctorPreferences() {
    return this.preferences.find((x) => x.type == 'doctors')
  }

  get benefitPreferences() {
    return this.preferences.find((x) => x.type == 'benefits')
  }

  get carrierName() {
    return this.newQuote.details.carrierName!
  }

  get carrierKey() {
    return this.newQuote.details.carrierKey!
  }

  get isImportantCarrier() {
    return (
      this.carrierPreferences?.values.includes(this.newQuote.details.carrierFilterKey!) ?? false
    )
  }

  get doctorCoverageDetail() {
    return this.newQuote.providerCoverage.providers
  }

  get inNetworkDoctors() {
    return this.doctorCoverageDetail.filter((x) => x.inNetwork)
  }

  get outOfNetworkDoctors() {
    return this.doctorCoverageDetail.filter((x) => !x.inNetwork)
  }

  get importantDoctors() {
    return this.doctorCoverageDetail.filter((x) => this.doctorPreferences?.values.includes(x.npi!))
  }

  get importantDoctorsInNetwork() {
    return this.importantDoctors.filter((x) => x.inNetwork)
  }

  get importantDoctorsOutOfNetwork() {
    return this.importantDoctors.filter((x) => !x.inNetwork)
  }

  get doctorInNetworkDifference() {
    if (!this.oldRec || !this.outOfNetworkDoctors.length) return []
    return this.oldRec.inNetworkDoctors.filter((x) =>
      this.outOfNetworkDoctors.map((x) => x.npi).includes(x.npi)
    )
  }

  get benefitCoverageDetail() {
    return this.newQuote.additionalBenefits.additionalBenefits
  }

  get coveredBenefits() {
    return this.benefitCoverageDetail.filter((x) => x.covered)
  }

  get uncoveredBenefits() {
    return this.benefitCoverageDetail.filter((x) => !x.covered)
  }

  get importantBenefits() {
    return this.benefitCoverageDetail.filter((x) =>
      this.benefitPreferences?.values.includes(x.sortKey)
    )
  }

  get importantBenefitsCovered() {
    return this.importantBenefits.filter((x) => x.covered)
  }

  get importantBenefitsUncovered() {
    return this.importantBenefits.filter((x) => !x.covered)
  }

  get benefitsCoveredDifference() {
    if (!this.oldRec || !this.uncoveredBenefits.length) return []
    return this.oldRec.coveredBenefits.filter((x) =>
      this.uncoveredBenefits.map((x) => x.sortKey).includes(x.sortKey)
    )
  }

  get drugCoverageDetail() {
    return this.newQuote.drugCoverage.coverageSummaries
  }

  get drugCost() {
    return this.newQuote.drugCost
  }

  get drugCostDifference() {
    if (!this.oldRec) return 0
    return this.oldRec.drugCost - this.drugCost
  }

  get policyType() {
    return hmoTypes.includes(this.newQuote.policyType)
      ? 'hmo'
      : ppoTypes.includes(this.newQuote.policyType)
      ? 'ppo'
      : '???'
  }

  get noChange() {
    return this.oldRec && this.oldRec.medicareId == this.newQuote.medicareId
  }

  get medicareId() {
    return this.newQuote.medicareId
  }

  get planYear() {
    return this.newQuote.details.year
  }

  get type() {
    return this.newQuote.type
  }

  constructor(
    preferences: PlandalfPreference[],
    newQuote: QuotedPlanVm,
    oldRec?: PlandalfRecommendation | null
  ) {
    this.preferences = preferences
    this.newQuote = newQuote
    this.oldRec = oldRec
  }
}
