<template>
  <NuxtLayout>
    <!--    removed page key because it was breaking application routing-->
    <!--    if something is breaking it might be this! -->
    <!--    <NuxtPage :page-key="$route.fullPath"/>-->
    <NuxtPage />
  </NuxtLayout>

  <PxStickyFlowHelp />
  <SiteChatWidget hide-launcher />

  <client-only>
    <AuviousWidget />
  </client-only>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { SiteStore } from '~/stores/site'
  import { CarrierStore } from '~/stores/carriers'
  import { ProfileStore } from '~/stores/profile'

  const session = SessionStore.use()
  const { isHealthCheck, isIndex, route, path, journeyId } = AppRoute.use()

  onMounted(async () => {
    TabSync.use()
    await AppInit.use()
    if (!process.dev) {
      const { start, isActive } = SessionReplay.use()
      const { isRobot } = RickDeckard.use()

      if (!isActive() && !isHealthCheck.value && !isRobot()) {
        start(session.profileId)
      }
    }
  })

  if (process.client) {
    const { $root, $rsReady } = useNuxtApp()
    const { track } = Analytics.use()
    const { push } = Gtm.use()

    const pxVisit = () => {
      if (_isNil(session.prevPage)) {
        setTimeout(() => {
          track('PxVisit', {
            from: document.referrer
          })
          push('PxVisit')
        }, 500)
      }
    }

    if ($rsReady) {
      pxVisit()
    } else {
      $root.on(RUDDERSTACK_READY, () => {
        pxVisit()
      })
    }

    const { isAuthenticated } = Auth.use()
    const profile = ProfileStore.use()

    let cleaningUp = false

    const cleanUp = async () => {
      if (cleaningUp) return

      cleaningUp = true

      await SessionStore.reset()

      const site = SiteStore.use()
      const { push } = AppRouter.use()

      if (!isIndex.value) {
        await push(site.journey.signOutPath!)
      }

      cleaningUp = false
    }

    watch(isAuthenticated, async (val, old) => {
      if (old !== val && val === false) {
        console.log('SIGNING OUT')
        await cleanUp()
      }
    })

    session.$subscribe(async () => {
      if (
        !isAuthenticated.value &&
        (!_isNil(profile.emailAddress?.address) || !_isNil(profile.mobilePhone?.number))
      ) {
        console.log('CLEANING UP')
        await cleanUp()
      }
    })
  }

  const site = SiteStore.use()
  await site.init()
  await CarrierStore.load()

  const config = useRuntimeConfig()

  useSeoMeta({
    robots: () =>
      isIndex.value &&
      (_isEmpty(route.query) || (!_isNil(journeyId.value) && journeyId.value !== 2)) &&
      config.public.sentryEnv === 'Production'
        ? 'index, nofollow'
        : 'noindex, nofollow'
  })

  const indexable = ['/account/sign-in', '/your-drugs', '/your-doctors']

  const meta = computed(() => {
    if (
      indexable.includes(path.value) &&
      config.public.sentryEnv === 'Production' &&
      _isEmpty(route.query)
    ) {
      return [
        {
          name: 'AdsBot-Google',
          content: 'index, nofollow'
        }
      ]
    }

    return []
  })

  // console.log('META', meta.value, path.value, route.query, indexable.includes(path.value))

  useHead({
    script: [
      {
        src: 'https://assets.calendly.com/assets/external/widget.js',
        type: 'text/javascript',
        async: true
      }
    ],
    link: [
      {
        rel: 'stylesheet',
        href: 'https://assets.calendly.com/assets/external/widget.css'
      }
    ],
    meta: meta
  })
</script>

<style scoped></style>
