import { defineStore } from 'pinia'
import { EngineStore } from '~/stores/engine'

import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
import { ConversationStore } from '~/stores/conversation'
import { PlandalfResponseGenerator } from '~/composables/PlandalfResponseGenerator'
import PlandalfPreferences from '~/composables/PlandalfPreferences'
import {
  type PlandalfQuote,
  PlandalfRecommendation,
  type PlandalfResponse
} from '~/models/PlandalfModels'
import { SessionStore } from '~/stores/session'
import { ProfileStore } from '~/stores/profile'
import { PlanType } from '~/generated/api-clients-generated'
import { RxStore } from '~/stores/rx'

export class PlandalfStore {
  static use = defineStore('plandalf', () => {
    const MAX_RECENTLY_VIEWED = 3

    const engine = EngineStore.use()
    const session = SessionStore.use()
    const profile = ProfileStore.use()
    const rxStore = RxStore.use()
    const conversation = ConversationStore.use()

    const { push } = AppRouter.use()

    const initialized = ref(false)
    const loading = ref<boolean>(false)
    const engineRunning = ref<boolean>(false)

    const isMapd = computed(() => session.planType == PlanType.MAPD)
    const isPdp = computed(() => session.planType == PlanType.PDP)
    const availablePlansCount = computed(() => engine.availableQuotes?.length)

    const showGettingStarted = ref(false)
    const isGettingStarted = computed(() => {
      if (_isEmpty(engine.params)) return true

      const params = isMapd.value
        ? [engine.params.rxs, engine.params.doctors, engine.params.additionalBenefits]
        : [engine.params.rxs]

      return params.reduce((acc, ls) => acc + (ls?.length ?? 0), 0) === 0
    })

    const {
      preferences,
      hasPendingPreferences,
      updatePreference,
      applyPreferences,
      resetPreferences,
      findPreference
    } = PlandalfPreferences.use()

    watch(
      () => session.planType,
      async (planType, oldPlanType) => {
        if (!_isNil(oldPlanType) && !_isNil(planType) && planType != oldPlanType) {
          session.plandalfPreferences = []
          resetPreferences()
          recommendedPlan.value = null
          recommendationResponse.value = null
          await init()
        }
      }
    )

    const recommendedPlan = ref<PlandalfRecommendation | null>(null)
    const displayedPlan = computed(() => (recommendedPlan.value as PlandalfRecommendation) ?? null)

    const nextRecommendedPlan = ref<PlandalfRecommendation | null>(null)
    const recommendationResponse = ref<PlandalfResponse | null>(null)
    const scoredPlans = ref<PlandalfQuote[]>([])
    const filteredPlans = computed(
      () =>
        (scoredPlans.value.filter((x) => !x.filteredBy?.length)?.map((x) => x.quote) ??
          []) as QuotedPlanVm[]
    )

    const top3Plans = computed(() => _take(filteredPlans.value, 3).map((x) => x.medicareId))
    const topPlansByCarrier = computed(() => {
      const currentCarrier = recommendedPlan.value!.carrierKey
      const others = _uniqBy(scoredPlans.value, (x) => x.quote.details.carrierFilterKey)
        .filter((x) => x.quote.details.carrierKey !== currentCarrier)
        .map((x) => x.quote.medicareId)

      return [recommendedPlan.value!.medicareId, ...others]
    })

    const lowerDrugCostsAvailable = computed(() =>
      filteredPlans.value.some((x: QuotedPlanVm) => x.drugCost < displayedPlan.value?.drugCost)
    )

    const previousRecommendation = computed(() => {
      const mostRecent = recentlyViewed.value.find(
        (x) => x?.quote?.medicareId != displayedPlan.value?.medicareId
      )

      return mostRecent
        ? new PlandalfRecommendation(mostRecent.preferences, mostRecent.quote as QuotedPlanVm)
        : null
    })

    const recentlyViewed = computed(() => {
      if (!displayedPlan.value) return []

      const rvPlans =
        displayedPlan.value.type == PlanType.MAPD
          ? session.plandalfRecentlyViewedMapd[displayedPlan.value.planYear!]
          : session.plandalfRecentlyViewedPdp[displayedPlan.value.planYear!]

      if (rvPlans) {
        return _take(
          _uniq(rvPlans).map((p) => ({
            preferences: p.preferences,
            quote: engine.quotes.find((q) => q.medicareId == p.medicareId)
          })),
          MAX_RECENTLY_VIEWED
        )
      }

      return []
    })

    async function compareTop3Plans() {
      session.comparedPlans = top3Plans.value
      await push('/compare')
    }

    async function compareTopPlansByCarrier() {
      session.comparedPlans = topPlansByCarrier.value
      await push('/compare')
    }

    async function viewPlanDetails() {
      await push(`/details/${recommendedPlan.value?.medicareId}`)
    }

    async function getRecommendation(
      delayMs?: number,
      fromEngineRun?: boolean,
      triggeredBy?: string
    ) {
      await fakeLoad(() => {
        console.log(
          'before plandalfStore.getRecommendation.applyPreferences: ',
          _clone(engine.availableQuotes?.length)
        )
        scoredPlans.value = applyPreferences((engine.availableQuotes as QuotedPlanVm[]) ?? [])
        console.log(
          'after plandalfStore.getRecommendation.applyPreferences: ',
          _clone(engine.availableQuotes?.length)
        )
        console.log('SCORED PLANS: ', scoredPlans)

        if (!scoredPlans.value.length) {
          console.error('NO PLANS')
          throw new Error('No Plans!')
        }

        const first = scoredPlans.value.find((x) => !x.filteredBy?.length)

        if (!first) {
          throw new Error('All plans are filtered!')
        }

        // previous rec = if a plan is displayed currently use that
        // otherwise look for the most recent plan from the session
        const previousRec = displayedPlan.value
          ? new PlandalfRecommendation(
              displayedPlan.value.preferences,
              displayedPlan.value.newQuote
            )
          : previousRecommendation.value

        console.log('PREVIOUS REC: ', previousRec)

        const currentPrefs = _cloneDeep(session.plandalfPreferences)
        const recommendation = new PlandalfRecommendation(
          currentPrefs,
          first.quote as QuotedPlanVm,
          previousRec
        )

        console.log('NEW REC: ', recommendation)

        const { getPrefResponse, getEngineResponse } = PlandalfResponseGenerator.use(
          currentPrefs,
          recommendation
        )

        nextRecommendedPlan.value = recommendation
        recommendationResponse.value = fromEngineRun
          ? getEngineResponse(triggeredBy)
          : getPrefResponse()
      }, delayMs ?? 3000)
    }

    async function takeRecommendation(addToRecentlyViewed: boolean = true) {
      if (!nextRecommendedPlan.value) {
        console.error('NO RECOMMENDATION TO TAKE')
        return
      }

      if (
        addToRecentlyViewed &&
        displayedPlan.value &&
        displayedPlan.value.medicareId != nextRecommendedPlan.value.medicareId
      ) {
        updateRecentlyViewed()
      }

      recommendedPlan.value = nextRecommendedPlan.value
      nextRecommendedPlan.value = null
    }

    const runEngine = async () => {
      try {
        loading.value = true
        engineRunning.value = true

        await fakeLoad(async () => {
          await EngineStore.run()
        }, 3000)
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        loading.value = false
        engineRunning.value = false
      }
    }

    function updateRecentlyViewed() {
      if (!displayedPlan.value) return

      const planYear = displayedPlan.value.planYear!
      const planType = displayedPlan.value.type

      let plansForCurrentYear =
        planType == PlanType.MAPD
          ? session.plandalfRecentlyViewedMapd[planYear]
          : session.plandalfRecentlyViewedPdp[planYear]

      if (!plansForCurrentYear) plansForCurrentYear = []

      const existingIndex = plansForCurrentYear.findIndex(
        (x) => x.medicareId === displayedPlan.value.medicareId
      )

      // ignore if already most recent
      if (existingIndex === 0) {
        return
      }

      // remove existing
      if (existingIndex > -1) {
        plansForCurrentYear.splice(existingIndex, 1)
      }

      // add to start of array
      const newLength = plansForCurrentYear.unshift({
        medicareId: displayedPlan.value.medicareId,
        preferences: displayedPlan.value.preferences
      })

      // truncate array
      if (newLength > MAX_RECENTLY_VIEWED) {
        plansForCurrentYear = plansForCurrentYear.slice(0, MAX_RECENTLY_VIEWED)
      }

      // update session with new array
      if (planType == PlanType.MAPD)
        session.plandalfRecentlyViewedMapd[planYear] = plansForCurrentYear
      else if (planType == PlanType.PDP)
        session.plandalfRecentlyViewedPdp[planYear] = plansForCurrentYear
    }

    // ensures code runs for at least X milliseconds
    // if it runs for longer than no additional wait is needed
    const fakeLoad = async (action: (() => void) | (() => Promise<void>), minWaitMs: number) => {
      loading.value = true

      let elapsed = 0
      const timer = setInterval(() => {
        elapsed += 100
        if (elapsed > minWaitMs) clearInterval(timer)
      }, 100)

      await action()

      const remaining = minWaitMs - elapsed
      if (remaining > 0) {
        await new Promise((resolve) => setTimeout(resolve, remaining))
      }

      loading.value = false
    }

    const { tagEvent } = SessionReplay.use()

    async function init(fromEngineRun: boolean = false) {
      console.log('PLANDALF INIT: ', displayedPlan.value, engine.availableQuotes.length)
      tagEvent('PlanFinder', {
        profileId: session.profileId
      })

      engine.initFilters()

      if (isGettingStarted.value) {
        showGettingStarted.value = true
      } else {
        showGettingStarted.value = false
        console.log('before plandalfStore.getRecommendation')
        await getRecommendation(0, fromEngineRun)
        console.log('after plandalfStore.getRecommendation')
        await takeRecommendation(false)
        await conversation.loadSection('feedback-loop', {
          message: recommendationResponse.value?.message
        })
      }

      initialized.value = true
    }

    const reset = async () => {
      recommendedPlan.value = null
      nextRecommendedPlan.value = null
      session.plandalfPreferences = []
      session.plandalfRecentlyViewedMapd = {}
      session.plandalfRecentlyViewedPdp = {}
      resetPreferences()
      await init()
    }

    return {
      initialized,
      loading,
      engineRunning,
      isMapd,
      isPdp,
      isGettingStarted,
      showGettingStarted,
      availablePlansCount,
      scoredPlans,
      filteredPlans,
      lowerDrugCostsAvailable,
      displayedPlan,
      previousRecommendation,
      recommendedPlan,
      nextRecommendedPlan,
      preferences,
      hasPendingPreferences,
      recommendationResponse,
      recentlyViewed,
      compareTop3Plans,
      compareTopPlansByCarrier,
      viewPlanDetails,
      resetPreferences,
      findPreference,
      updatePreference,
      getRecommendation,
      takeRecommendation,
      fakeLoad,
      runEngine,
      reset,
      init
    }
  })

  static async init() {
    console.log('PlandalfStore.init()')
    const store = PlandalfStore.use()
    await store.init()
  }
}
