import { defineStore } from 'pinia'
import { type PlanCheckup, PlanType } from '~/generated/api-clients-generated'
import dayjs from 'dayjs'
import { ProfileStore } from '~/stores/profile'
import { ApplicationStore } from '~/stores/applications'

export enum PlanCheckupPhase {
  Inactive,
  PreAep,
  DuringAep
}

export enum PlanCheckupStatus {
  Incomplete,
  Ready,
  BetterPlan,
  SamePlan,
  NoPlan
}

export class PlanCheckupStore {
  static use = defineStore('planCheckup', () => {
    const profile = ProfileStore.use()
    const applications = ApplicationStore.use()

    const planCheckups = ref<Array<PlanCheckup>>([])
    const sync = ref(false)

    const planYear = computed(() => dayjs().year() + 1)
    const currentCheckup = computed(() =>
      planCheckups.value.find((pc) => pc.planYear === planYear.value)
    )

    const activeStatuses = [
      'submitted',
      'in_progress',
      'received',
      'pending',
      'approved',
      'delivery_failed'
    ]

    const nextYearPolicy = computed(() => {
      return applications.applications.find(
        (app) =>
          app.planYear === planYear.value &&
          !!app.planType &&
          [PlanType.MAPD, PlanType.PDP].includes(app.planType)
        //&& activeStatuses.includes(app.status ?? '')
      )
    })

    const status = computed<PlanCheckupStatus>(() => {
      if (
        _isNil(currentCheckup.value) ||
        _isNil(currentCheckup.value?.needsConfirmedTimestamp) ||
        dayjs(currentCheckup.value?.needsConfirmedTimestamp).isBefore(dayjs().subtract(1, 'month'))
      )
        return PlanCheckupStatus.Incomplete

      if (_isNil(currentCheckup.value?.bestPlanId) || phase.value === PlanCheckupPhase.PreAep)
        return PlanCheckupStatus.Ready

      if (samePlanRecommended.value) return PlanCheckupStatus.SamePlan

      return _isNil(currentCheckup.value?.currentPlanId)
        ? PlanCheckupStatus.NoPlan
        : PlanCheckupStatus.BetterPlan
    })

    const createCheckup = async () => {
      const { createPlanCheckupClient } = PxApi.use()
      const client = createPlanCheckupClient()

      const apps = ApplicationStore.use()

      const currentPolicy = apps.applications.find(
        (a) =>
          a.planYear === planYear.value - 1 &&
          [PlanType.PDP, PlanType.MAPD].includes(a.planType!) &&
          a.status === 'approved'
      )

      const guid = await client.createPlanCheckup(
        profile.id!,
        planYear.value,
        currentPolicy?.medicareId,
        currentPolicy?.planType
      )

      planCheckups.value.push({
        id: guid,
        planYear: planYear.value,
        currentPlanId: currentPolicy?.medicareId,
        currentPlanType: currentPolicy?.planType
      })
    }

    const updateCheckup = async (updates: Partial<PlanCheckup>) => {
      let checkup = planCheckups.value.find((c) => c.id === currentCheckup.value!.id)
      checkup = { ...checkup, ...updates }

      const { createPlanCheckupClient } = PxApi.use()
      const client = createPlanCheckupClient()

      await client.updatePlanCheckup(currentCheckup.value!.id!, checkup)

      planCheckups.value = [...planCheckups.value.filter((pc) => pc.id !== checkup.id), checkup]
    }

    function init(ls: Array<PlanCheckup> | null = []) {
      planCheckups.value = [...(ls ?? [])]
      sync.value = true
    }

    const { flag } = FeatureFlags.use()

    const checkupEnabled = computed(() => flag<boolean>('plan-checkup').value)

    const recsReady = computed(() => flag<boolean>('next-year-recommendations-enabled').value)

    const samePlanRecommended = computed(
      () =>
        !_isNil(currentCheckup.value?.bestPlanId) &&
        currentCheckup.value?.bestPlanId === currentCheckup.value.currentPlanId
    )

    const phase = computed<PlanCheckupPhase>(() => {
      if (!checkupEnabled.value) return PlanCheckupPhase.Inactive
      if (!recsReady.value) return PlanCheckupPhase.PreAep
      return PlanCheckupPhase.DuringAep
    })

    const contentSuffix = computed(() =>
      !!phase.value ? PlanCheckupPhase[phase.value].toString() : null
    )

    const contentPropertyKey = computed(() =>
      _snakeCase(PlanCheckupStatus[status.value].toString())
    )

    return {
      sync,
      init,
      planCheckups,
      currentCheckup,
      createCheckup,
      updateCheckup,
      status,
      recsReady,
      checkupEnabled,
      samePlanRecommended,
      phase,
      contentSuffix,
      contentPropertyKey,
      nextYearPolicy
    }
  })

  static init(ls: Array<PlanCheckup> | null = []) {
    const store = PlanCheckupStore.use()
    store.init(ls)
  }
}
