import dayjs from 'dayjs'
import type { MaybeRef } from 'vue'

export class SupportHours {
  static use() {
    const { flag } = FeatureFlags.use()
    const isAep = computed(() => flag<boolean>('is-aep').value)

    const nowCentral = computed(() => dayjs().tz('America/Chicago'))
    const isWeekend = computed(() => [0, 6].includes(nowCentral.value.day()))

    const getTime = (date: MaybeRef<dayjs.Dayjs>, hour: number) =>
      toValue(date).startOf('day').add(hour, 'h')

    const getHours = computed(() => (!isAep.value || isWeekend.value ? [9, 18] : [8, 21]))

    const isSupportHours = computed(() => {
      if (isWeekend.value && !isAep.value) return false

      const [start, end] = getHours.value

      return nowCentral.value.isBetween(
        getTime(nowCentral, start),
        getTime(nowCentral, end),
        null,
        '[]'
      )
    })

    return {
      isSupportHours
    }
  }
}
