import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'
import { SiteStore } from '~/stores/site'

export default class SkipLocationCollectionNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const { flag } = FeatureFlags.use()
    const site = SiteStore.use()

    const config = flag<any>('index-by-journey')
    const version = config.value[site.journey!.id!.toString()]

    return this.yesNo(['easy-to-enroll'].includes(version))
  }
}
