<template>
  <PxStickyContainer
    v-if="!hideHelpBar"
    position="bottom"
    class="justify-center border-t-2 border-primary-300 border-opacity-50 bg-primary-100 py-2 md:!py-3"
  >
    <HpButton
      id="sticky-help-btn"
      class="max-w-56"
      @click="showChat('talkdesk')"
      variant="secondary"
    >
      <HpIcon name="help" class="mr-1" />
      {{ content.ext.help_text }}
    </HpButton>
  </PxStickyContainer>
</template>

<script lang="ts" setup>
  import { PlanType } from '~/generated/api-clients-generated'
  import { EngineStore } from '~/stores/engine'

  const { getComponentContent } = Cms.use()
  const { content } = await getComponentContent('QuotesStickyActions', 'BottomBar')

  const { isIndex, path, journeyId } = AppRoute.use()
  const engine = EngineStore.use()
  const { show: showChat } = ChatController.use()

  const hideHelpBar = computed(
    () =>
      (path.value == '/quotes' && engine.selectedPlanType !== PlanType.GAP) ||
      path.value == '/compare' ||
      path.value.includes('/details') ||
      path.value.includes('/apply') ||
      path.value == '/dashboard' ||
      path.value == '/standby' ||
      path.value == '/confirm'
  )
</script>

<style scoped></style>
