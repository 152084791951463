<script setup lang="ts">
  const props = defineProps<{
    position: 'bottom-left' | 'bottom' | 'bottom-right'
    hide?: boolean | null
    transition?: 'slide-y' | 'slide-x'
    delay?: number
  }>()

  const attr = useAttrs()

  const classes = computed(() => [
    attr.class,
    'sticky-container',
    {
      'bottom-left': props.position == 'bottom-left',
      bottom: props.position == 'bottom'
    }
  ])
</script>

<template>
  <template v-if="transition == 'slide-y'">
    <TransitionSlide
      appear
      :enter="{
        from: {
          y: 50
        },
        duration: 0.4,
        delay: delay ?? 0
      }"
      :leave="{
        to: {
          y: 50
        },
        duration: 0.4
      }"
    >
      <div v-if="!hide" :class="classes">
        <slot></slot>
      </div>
    </TransitionSlide>
  </template>
  <div v-else :class="classes">
    <slot></slot>
  </div>
</template>

<style scoped>
  .sticky-container {
    @apply fixed z-20;

    &.bottom {
      @apply bottom-0 flex w-full shadow-menu;
    }

    &.bottom-left {
      @apply bottom-5 left-5 flex shadow-menu;
    }
  }
</style>
