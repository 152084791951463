import { EngineStore } from '~/stores/engine'
import {
  type PlandalfPreference,
  PlandalfRecommendation,
  type PlandalfResponse
} from '~/models/PlandalfModels'
import { concatValues, formatUsd, isVowel } from '~/utils/format'
import { PlanType } from '~/generated/api-clients-generated'

export class PlandalfResponseGenerator {
  static use(applicablePreferences: PlandalfPreference[], recommendation: PlandalfRecommendation) {
    const engine = EngineStore.use()
    const { benefitOptions, getRandomCarriers } = PlandalfOptions.use()

    const getBenefitText = (value: number) =>
      benefitOptions.value.find((x) => x.value == value)?.text

    function buildPlanDescriptor(rec: PlandalfRecommendation, noPreference?: boolean) {
      let builder = ''

      if (noPreference) {
        builder += `${rec.carrierName} `
      } else {
        if (rec.carrierPreferences?.values?.length) {
          builder += `${isVowel(rec.carrierName[0]) ? 'an' : 'a'} ${rec.carrierName} `
        } else {
          builder += 'a '
        }
      }

      if (applicablePreferences.some((x) => x.type == 'policyType' && x.values.length)) {
        builder += `${rec.policyType.toUpperCase()} `
      }

      builder += 'plan'
      return builder
    }

    const isPerfectMatch = (rec: PlandalfRecommendation) => {
      if (rec.type == PlanType.MAPD) {
        if (rec.doctorCoverageDetail.some((x) => !x.inNetwork)) return false
        if (rec.benefitCoverageDetail.some((x) => !x.covered)) return false
        if (rec.drugCoverageDetail.some((x) => !x.isCovered)) return false
      }

      if (rec.type == PlanType.PDP) {
        if (rec.drugCoverageDetail.some((x) => !x.isCovered)) return false
      }
      return true
    }

    function getEngineResponse(triggeredBy?: string) {
      console.log('ENGINE RESPONSE: START')
      if (triggeredBy && recommendation.noChange) {
        console.log('ENGINE RESPONSE: NO CHANGE')
        return {
          message: `Based on the ${triggeredBy} information you provided we still think this is the best choice for you. Want to know more?`
        }
      }

      let builder = `We've combed through the ${
        engine.availableQuotes?.length
      } plans in your area from carriers like ${concatValues(getRandomCarriers(3))}`

      if (isPerfectMatch(recommendation)) {
        console.log('ENGINE RESPONSE: PERFECT MATCH')
        builder += ` and found this ${buildPlanDescriptor(recommendation, true)}`

        if (recommendation.type == PlanType.MAPD) {
          builder += ' that has everything you need plus other great benefits. Want to know more?'
        }

        if (recommendation.type == PlanType.PDP) {
          builder += ' that has the lowest costs for the drugs you added. Want to know more?'
        }

        return {
          message: builder
        } as PlandalfResponse
      }

      const coverageDetails = []
      if (recommendation.doctorCoverageDetail.length)
        coverageDetails.push({
          amt: recommendation.doctorCoverageDetail.filter((x) => x.inNetwork).length,
          type: 'doctor'
        })
      if (recommendation.drugCoverageDetail.length)
        coverageDetails.push({
          amt: recommendation.drugCoverageDetail.filter((x) => x.isCovered).length,
          type: 'drug'
        })
      if (recommendation.benefitCoverageDetail.length)
        coverageDetails.push({
          amt: recommendation.benefitCoverageDetail.filter((x) => x.covered).length,
          type: 'benefit'
        })

      if (coverageDetails.some((x) => x.amt > 0)) {
        builder += `.`

        builder += ` This ${buildPlanDescriptor(
          recommendation,
          true
        )} is the closest match, offering coverage for `

        builder += `${concatValues(
          coverageDetails
            .filter((x) => x.amt > 0)
            .map((x) => `${x.amt} ${x.amt > 1 ? x.type + 's' : x.type}`)
        )}.`
      } else {
        builder += ` but didn't find any plan that covers your ${concatValues(
          coverageDetails.map((x) => x.type),
          false,
          'or'
        )}s.`

        builder += ` This ${buildPlanDescriptor(
          recommendation,
          true
        )} is one we think you may still like.`
      }

      console.log('ENGINE RESPONSE: END')
      return {
        message: (builder += '<br style="margin-bottom: 20px"><strong>Want to know more?</strong>')
      } as PlandalfResponse
    }

    const hasDifferences = (rec: PlandalfRecommendation) =>
      rec.drugCostDifference < 0 ||
      rec.doctorInNetworkDifference.length > 0 ||
      rec.benefitsCoveredDifference.length > 0

    function getPrefResponse(): PlandalfResponse {
      console.log('PREFERENCE RESPONSE: START')
      if (!applicablePreferences.length) return getEngineResponse()

      if (recommendation.oldRec && recommendation.noChange) {
        console.log('PREFERENCE RESPONSE: NO CHANGE')
        // todo: make this prompt the user to see the next best plan
        return {
          message:
            'We still think this plan is the best match for your preferences. Want to know more?'
        }
      }

      if (isPerfectMatch(recommendation) || !hasDifferences(recommendation)) {
        console.log('PREFERENCE RESPONSE: PERFECT MATCH')
        return {
          message: 'We found a great plan for your needs. Want to know more about this one?'
        }
      }

      let message = `We found `

      // e.g an Aetna plan / a Wellcare PPO plan / a plan
      message += buildPlanDescriptor(recommendation)

      let coverageMessages = []
      let sacrificialMessages = []

      const mostImportantPreference = applicablePreferences[0]

      switch (mostImportantPreference.type) {
        case 'drugs':
          coverageMessages.push(`that keeps your drug costs low`)
          break
        case 'doctors':
          const doctorAmt =
            mostImportantPreference.values.length <= recommendation.importantDoctorsInNetwork.length
              ? 'all'
              : recommendation.importantDoctorsInNetwork.length

          coverageMessages.push(`covers ${doctorAmt} of your important doctors`)
          break
        case 'benefits':
          const benefitAmt =
            mostImportantPreference.values.length <= recommendation.importantBenefitsCovered.length
              ? 'all'
              : recommendation.importantBenefitsCovered.length

          coverageMessages.push(`covers ${benefitAmt} of your important benefits`)
          break
      }

      if (mostImportantPreference.type != 'drugs' && recommendation.drugCostDifference < 0) {
        const fmtCostDiff = `${formatUsd(Math.abs(recommendation.drugCostDifference))}`
        sacrificialMessages.push(`your yearly drug cost would be ${fmtCostDiff} higher`)
      }

      if (
        mostImportantPreference.type != 'doctors' &&
        recommendation.doctorInNetworkDifference.length > 0
      ) {
        const oon = recommendation.doctorInNetworkDifference.map((x) => x.name!)
        sacrificialMessages.push(
          `${concatValues(oon, true)} ${oon.length > 1 ? 'are' : 'is'} out of this plan's network`
        )
      }

      if (
        mostImportantPreference.type != 'benefits' &&
        recommendation.benefitsCoveredDifference.length > 0
      ) {
        const notCovered = recommendation.benefitsCoveredDifference.map(
          (x) => getBenefitText(x.sortKey!)!
        )
        sacrificialMessages.push(
          `${concatValues(notCovered)} ${notCovered.length > 1 ? 'are' : 'is'} not covered`
        )
      }

      if (coverageMessages.length) message += ` that ${concatValues(coverageMessages)}`

      if (sacrificialMessages.length) message += `, but ${concatValues(sacrificialMessages)}`

      message += '. Do you want to check it out?'

      console.log('PREFERENCE RESPONSE: END')
      return {
        message: message,
        needsConfirmation: true
      }
    }

    return {
      getPrefResponse,
      getEngineResponse
    }
  }
}
