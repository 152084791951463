import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'
import dayjs from 'dayjs'

export default class IsDecemberNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    return this.yesNo(now().month() === 11)
  }
}
