import DecisionNodeBase from '~/models/journey/DecisionNodeBase'
import type { WaypointNode } from '~/models/journey/waypoints'
import undefined from '~/components/page/undefined.vue'
import type { Path } from '~/types/util'
import { SessionStore } from '~/stores/session'
import { PlanType } from '~/generated/api-clients-generated'

export default class IsMapdPlanTypeNode extends DecisionNodeBase {
  getNext(): WaypointNode | Path | null {
    const session = SessionStore.use()

    return this.yesNo(session.planType === PlanType.MAPD)
  }
}
